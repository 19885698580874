export function getEnvironment(): string {
  switch (location.origin) {
    case 'http://localhost:4200':
      return 'staging';
    case 'https://staging-coalition.enrollandpay.com':
    case 'https://nabagent-staging.enrollandpay.com':
      return 'staging';
    case 'https://preproduction-coalition.enrollandpay.com':
      return 'preprod';
    case 'https://coalition.enrollandpay.com':
    case 'https://netelement-coalition.enrollandpay.com':
    case 'https://nabagent.enrollandpay.com':
    case 'https://gspagent.enrollandpay.com':
    case 'https://cocardagent.enrollandpay.com':
    case 'https://coalition.eagleprocessingrewards.com':
      return 'prod';
    default:
      throw Error('Unexpected base URL');
  }
}
