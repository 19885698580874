import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin.component';
import { Shell } from '@app/shell/shell.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: ':coalitionId/admin',
      component: AdminComponent,
      data: { title: marker('Enroll And Pay') },
    },
    {
      path: ':coalitionId/admin/representatives',
      loadChildren: () =>
        import('./submodules/representatives/representatives.module').then((m) => m.RepresentativesModule),
    },
    {
      path: ':coalitionId/admin/teams',
      loadChildren: () => import('./submodules/teams/teams.module').then((m) => m.TeamsModule),
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
