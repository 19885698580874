import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationProfile } from '@app/@shared/models/application-profile.model';
import { ApplicationProfileService } from '@app/@shared/services/application-profile.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfigurationService, LoadingSpinnerDirective, ViewCardComponent, ViewCardConfig } from '@ep/shared';
import { NgForOf, NgIf } from '@angular/common';
import { IframeManagerService } from '@shared/services/iframe-manager.service';
import { ColorsEnum, RepresentativeAccessTypes } from '@shared/enums';
import { Subscription } from 'rxjs';
import { environment } from '@env/environment';

export interface AdminCardConfig {
  config: ViewCardConfig;
  accessType: RepresentativeAccessTypes;
  path: string;
}

@UntilDestroy()
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  standalone: true,
  imports: [NgForOf, NgIf, LoadingSpinnerDirective, ViewCardComponent],
})
export class AdminComponent implements OnInit {
  private readonly applicationProfileService = inject(ApplicationProfileService);
  private readonly configurationService = inject(ConfigurationService);
  private readonly iframeManagerService = inject(IframeManagerService);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);

  coalitionId: string | null = null;
  isLoading: boolean = false;

  cardConfigurations: AdminCardConfig[] = [
    {
      config: {
        header: 'Representatives',
        subheader: 'Configure',
        pathToImg: '/assets/images/line-graph.png',
        backgroundColor: ColorsEnum.LIGHT_BLUE,
      },
      accessType: RepresentativeAccessTypes.RepresentativeAdmin,
      path: 'representatives',
    },
    {
      config: {
        header: 'Teams',
        subheader: 'Configure',
        pathToImg: '/assets/images/line-graph.png',
        backgroundColor: ColorsEnum.LIGHT_BLUE,
      },
      accessType: RepresentativeAccessTypes.TeamAdmin,
      path: 'teams',
    },
  ];

  applicationProfile: ApplicationProfile | null = null;
  private subs = new Subscription();

  ngOnInit() {
    this.subs.add(
      this.applicationProfileService.applicationProfile$
        .pipe(untilDestroyed(this))
        .subscribe((applicationProfile: ApplicationProfile) => {
          this.applicationProfile = applicationProfile;
        })
    );
  }

  protected trackByHeader(index: number, item: AdminCardConfig) {
    return item.config.header;
  }

  protected hasPermission(accessType: RepresentativeAccessTypes) {
    if (this.applicationProfile) {
      const representativeAccess = this.applicationProfile.Representative.AccessType & accessType;
      return representativeAccess === accessType;
    }
    return false;
  }

  navigateToPath(path: string) {
    const iframe = this.iframeManagerService.generateIframeSrc(
      this.configurationService.coalitionPortalUrlLegacy ?? environment.coalitionPortalUrlLegacy,
      this.applicationProfileService.getApplicationProfile().Representative.CoalitionId,
      path.toLowerCase()
    );

    this.iframeManagerService.setIframeSrc(iframe);

    this.router.navigate([path], { relativeTo: this.route });
  }
}
