import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, shareReplay } from 'rxjs';
import { Credentials } from '@shared/models/credentials.model';

@Injectable({
  providedIn: 'root',
})
export class LegacyLoadedService {
  private _isLegacyLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  readonly isLegacyLoaded$: Observable<boolean> = this._isLegacyLoaded$.asObservable().pipe(shareReplay(1));

  setLegacyLoadedStatus(isLoaded: boolean) {
    this._isLegacyLoaded$.next(isLoaded);
  }

  get isLegacyLoaded() {
    return this._isLegacyLoaded$.value;
  }

  public authenticateLegacy$(coalitionPortal: string, userCredentials: Credentials | null) {
    const credentials = userCredentials;
    const iframe = `${coalitionPortal}/#Impersonate/${credentials?.jwt}/${credentials?.clientAccessId}`;

    return of(iframe);
  }
}
